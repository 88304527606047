import APIService from '@/services/api-service'
const resource = '/supplier'

class SupplierService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public getSupplier (id: any) {
    return this.connector.get(`${resource}/${id}`)
  }

  public getAddress (supplierId: any, type: any) {
    return this.connector.get(`${resource}/${supplierId}/address/${type}`)
  }

  public getContactDetails (contactId: any) {
    return this.connector.get(`${resource}/contact/${contactId}`)
  }

  public checkSupplier (code: any) {
    return this.connector.get(`${resource}/check/${code}`)
  }

  public create (supplier: any) {
    return this.connector.post(`${resource}/create`, { supplier: supplier })
  }

  public update (supplier: any) {
    return this.connector.put(`${resource}/update`, { supplier: supplier })
  }
}

export default new SupplierService()
