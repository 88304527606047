import APIService from '@/services/api-service'
const resource = '/supplier'

class SupplierService extends APIService {
  public constructor () {
    super()
    this.mountAuthorizationToken()
  }

  public suppliers () {
    return this.connector.get('/suppliers')
  }

  public delete (id: any) {
    return this.connector.delete(`${resource}/${id}`)
  }
}

export default new SupplierService()
