
import {
  Component,
  Vue
} from 'vue-property-decorator'
import SupplierService from './services/supplier-service'
import SupplierModal from './components/SupplierModal.vue'
import Status from '@/components/Status/index.vue'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import {
  ToastModule
} from '@/store/modules/ToastModule'
  @Component({
    name: 'Suppliers',
    components: {
      SupplierModal,
      Status
    }
  })
export default class Supplier extends Vue {
  protected isTableBusy = false
  protected perPage = 10
  protected currentPage = 1
  protected pageOptions: any = [10, 25, 50, 100]
  protected searchTable = null
  private fields = [{
    key: 'index',
    label: '#',
    thStyle: {
      width: '3%'
    },
    class: 'text-center'
  },
  {
    key: 'name',
    thStyle: {
      width: '20%'
    }
  },
  {
    key: 'code',
    thStyle: {
      width: '10%'
    }
  },
  {
    key: 'email',
    thStyle: {
      width: '15%'
    }
  },
  {
    key: 'phone',
    label: 'Contact Number',
    thStyle: {
      width: '10%'
    }
  },
  {
    key: 'created_date',
    thStyle: {
      width: '10%'
    }
  },
  {
    key: 'action',
    label: '',
    thStyle: {
      width: '5%'
    },
    class: 'text-center'
  }
  ]

  private suppliers: any = []

  get rows () {
    return this.suppliers.length
  }

  created () {
    this.boot()
  }

  protected boot () {
    BreadcrumbModule.setBreadcrumb(this.$route)
    this.populateSuppliers()
  }

  public async populateSuppliers () {
    this.isTableBusy = true
    const response = await SupplierService.suppliers()
    this.isTableBusy = false
    this.suppliers = response.data
  }

  public openModal () {
    (this.$refs.SupplierInfoModal as any).open()
  }

  protected edit (id: any) {
    (this.$refs.SupplierInfoModal as any).populateSupplier(id);
    (this.$refs.SupplierInfoModal as any).open()
  }

  public deleteSupplier (supplier: any) {
    const msg = `Do you want to delete ${supplier.name} ?`
    if (confirm(msg) === true) {
      SupplierService.delete(supplier.id).then((response) => {
        ToastModule.message(response.data.message)
        this.suppliers = []
        this.populateSuppliers()
      }).catch(error => {
        console.log(error)
      })
    }
  }
}

